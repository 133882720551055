import React from 'react'
import PropTypes from 'prop-types'
import '../../pages/building-apps-crash-course/building-apps-crash-course.scss'
import Image from '../Image'

const PersonDetails = ({ type, name, description }) => (
  <div className="u-flex bacc_person-wrapper">
    <Image
      type={type}
      alt={name}
      wrapperStyles={{
        width: '48px',
      }}
    />
    <div className="bacc_person-details">
      <div className="u-font-medium bacc_person-name">{name}</div>
      <div className="bacc_person-description">{description}</div>
    </div>
  </div>
)

PersonDetails.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
}

export default PersonDetails
