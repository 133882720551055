import React, { Fragment, useState, useEffect } from 'react'
import { Link } from 'gatsby'
import Hero from '../../components/BuildingAppsCrashCourse/Hero'
import Layout from '../../layout'
import CheckIcon from '../../assets/icons/check.svg'
import LinkIcon from '../../assets/icons/link.svg'
import TwitterIcon from '../../assets/icons/twitter-2.svg'
import YoutubeIcon from '../../assets/icons/youtube.svg'
import LinkedinIcon from '../../assets/icons/linkedin-2.svg'
import DiscordIcon from '../../assets/icons/discord.svg'
import CheckIcon2 from '../../assets/icons/check-2.svg'
import BuildingAppsCrashCourseOgImage from '../../assets/images/building-apps-crash-course-og-image.png'
import LessonSection from '../../components/BuildingAppsCrashCourse/LessonSection'
import PersonDetails from '../../components/BuildingAppsCrashCourse/PersonDetails'
import ConsoleBanner from '../../components/BuildingAppsCrashCourse/ConsoleBanner'
import Analytics from '../../utils/analytics'
import YoutubeVideo from '../../components/BuildingAppsCrashCourse/YoutubeVideo'
import { useAuth } from '../../hooks/useAuth'
import { logAmplitudeEvent } from '../../utils/amplitude'
import { canUseWindow } from '../../utils'
import { SEO } from '../../components'
import './building-apps-crash-course.scss'

const checklist = [
  'How to create an app from start to finish',
  'Best programming practices',
  'How to effectively use the LiveChat API',
  'Superb developer tools to facilitate your workflow',
  'Different hosting options and how to use them',
  'How to excel at LiveChat app review',
  'Monetization options at LiveChat Developer Program',
]

const BuildingAppsCrashCourse = () => {
  const [copied, setCopied] = useState(false)
  const {
    user: { license_id, email },
  } = useAuth()

  const track = (label, action) => {
    Analytics.track({
      category: 'Interaction',
      action: action,
      label: label,
      license: license_id,
    })

    logAmplitudeEvent('button.clicked', { cta: 'Start free course' }, email)
  }

  const copyLink = () => {
    if (canUseWindow) {
      navigator.clipboard.writeText(window.location.href)
      setCopied(true)
    }
  }

  useEffect(
    () => {
      const timeout = setTimeout(() => {
        if (copied) setCopied(false)
      }, 1000)

      return () => clearTimeout(timeout)
    },
    [copied]
  )

  return (
    <Layout className="bacc_layout-styles" isCourseSite>
      <SEO
        title="Building apps crash course"
        description="Watch our free building apps crash course to learn the ins and outs of app development and master skills vital for growth as a junior developer."
        image={BuildingAppsCrashCourseOgImage}
      />
      <div className="bacc_main-wrapper">
        <section className="u-flex u-flex-col u-items-center u-justify-center bacc_main-section">
          <h3 className="u-text-center u-font-normal bacc_subtitle">
            course for junior developers
          </h3>
          <h1 className="u-text-center bacc_heading">
            Building apps crash course
          </h1>
          <p className="u-text-center bacc_p-text-header">
            Learn the ins and outs of app development and become an expert in
            building apps with the LiveChat Developer Program.
          </p>
          <Link to="/building-apps-crash-course/plan-app-development/">
            <button
              className="u-font-medium bacc_btn-blue"
              data-controller="track"
              data-track-category="Interaction"
              data-track-action="Button"
              data-track-label="Start free course"
              data-action="track#send"
              onClick={() => track('Button', 'Start free course')}
            >
              Start free course
            </button>
          </Link>
          <div className="u-flex u-items-center">
            <CheckIcon className="check-white" />
            <span className="u-ml-sm">No signup required</span>
          </div>
        </section>
        <YoutubeVideo id="kxNtckTJKCQ" eventLabel="intro" />
        <section className="u-flex u-flex-col bacc_main-section">
          <div className="u-flex u-items-center u-justify-between u-flex-wrap bacc_about-sec-gap">
            <h3 className="u-font-bold bacc_section-title u-my-0">
              About the course
            </h3>
            <div className="u-flex bacc_about-sec-gap">
              <div
                className="u-flex u-items-center u-justify-center bacc_social-icon link"
                onClick={copyLink}
              >
                {!copied ? <LinkIcon /> : <CheckIcon2 />}
              </div>
              <a
                className="u-flex u-items-center u-justify-center bacc_social-icon youtube"
                href={process.env.GATSBY_PRODUCT_YOUTUBE_INSIDER_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <YoutubeIcon />
              </a>
              <a
                className="u-flex u-items-center u-justify-center bacc_social-icon linkedin"
                href={process.env.GATSBY_PRODUCT_LINKEDIN_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedinIcon />
              </a>
              <a
                className="u-flex u-items-center u-justify-center bacc_social-icon twitter"
                href={process.env.GATSBY_PRODUCT_TWITTER_PLATFORM_URL}
                target="_blank"
                rel="noopener noreferrer"
              >
                <TwitterIcon />
              </a>
              <a
                className="u-flex u-items-center u-justify-center bacc_social-icon discord"
                href={process.env.GATSBY_DISCORD_LINK}
                target="_blank"
                rel="noopener noreferrer"
              >
                <DiscordIcon />
              </a>
            </div>
          </div>
          <p className="bacc_p-text">
            We created The Practical Guide to Building LiveChat Apps to make
            your beginnings in the marketplace apps industry a tad easier. We’re
            really excited to share general tips on programming straight from
            our developer teams, but also, teach you how to build an app with
            the Developer Program from A to Z.
          </p>
          <p className="bacc_p-text">
            From this course, you’ll get legit coding advice, techniques, and
            see practical tools we use on a daily basis in our own work. We’ll
            also provide you with resources required to progress through the
            episodes, as well as materials useful for your further individual
            work.
          </p>
        </section>
        <section className="bacc_main-section">
          <h3 className="u-font-bold bacc_section-title">What you’ll learn </h3>
          <ul className="u-flex u-flex-col bacc_ul-det">
            {checklist.map((item, index) => (
              <li key={index}>
                <CheckIcon2 />
                {item}
              </li>
            ))}
          </ul>
        </section>
        <Hero
          name="Thomas de Becdelièvre"
          position="Co-Founder & CTO, SnapCall"
          quotation={
            <Fragment>
              "Within a month of work and the help of the LiveChat team, our
              developers were able to deliver a fully functional beta version"
            </Fragment>
          }
          image="snapcallCto"
          alt="Thomas de Becdelièvre, SnapCall"
          link={{
            link: `${
              process.env.GATSBY_DEVELOPER_WEBSITE_URL
            }updates/livechat-snapcall-app-case-study`,
            label: 'Read the story',
          }}
        />
        <section className="bacc_main-section">
          <h3 className="u-font-bold bacc_section-title">Authors</h3>
          <div className="bacc_grid-dub-col">
            <PersonDetails
              type="olaKacperczyk"
              name="Aleksandra Kacperczyk"
              description="Developer Education Leader"
            />
            <PersonDetails
              type="oliwiaPolec"
              name="Oliwia Połeć"
              description="Developer Education"
            />
            <PersonDetails
              type="arturFracala"
              name="Artur Frącala"
              description="Engineer at Developer Education"
            />
            <PersonDetails
              type="jedrzejMasternak"
              name="Jędrzej Masternak"
              description="Developer Relations"
            />
            <PersonDetails
              type="kamilaTokarska"
              name="Kamila Tokarska"
              description="Developer Education"
            />
          </div>
        </section>
        <LessonSection />
      </div>
      <ConsoleBanner />
    </Layout>
  )
}

export default BuildingAppsCrashCourse
