import React from 'react'
import PropTypes from 'prop-types'
import Image from '../Image'
import '../../pages/building-apps-crash-course/building-apps-crash-course.scss'

const Hero = ({ name, position, image, alt, quotation, link }) => {
  return (
    <section className="bacc_main-section bacc_grid-dub-col">
      <Image type={image} alt={alt} />
      <div className="bacc_hero-text-center">
        <h3 className="u-font-bold bacc_hero-text u-my-0">
          <blockquote>{quotation}</blockquote>
        </h3>
        <div className="u-font-medium bacc_person-name">
          <p className="u-my-0">{name}</p>
          <p className="u-font-normal bacc_person-description">{position}</p>
        </div>
        <a
          href={link.link}
          target="_blank"
          rel="noopener noreferrer"
          className="c-link bacc_study-link u-font-medium"
        >
          {link.label}
        </a>
      </div>
    </section>
  )
}

Hero.propTypes = {
  name: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.string,
  alt: PropTypes.string,
  quotation: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  link: PropTypes.object,
}

export default Hero
